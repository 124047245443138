import { useState, useEffect } from 'react';
import Appbar from './Appbar.js';
import App    from '../App.js';

const Controller = () => {
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart") || "[]"));

    const removeFromCart = (productRemove) => {
        setCart(
        cart.slice(0,productRemove.index)
            .concat(cart.slice(productRemove.index+1,cart.length))
        )
//        console.log(Object.keys(cart).filter(key => productRemove
//        const x = Object.keys(cart).filter(key => key != productRemove.index)
//            .reduce((obj,key) => { obj[key] = cart[key]; return obj; }, {});
//        console.log(Object.values(x))
//        const newCart = Object.keys(cart).filter((key) => key != productRemove.index)
//        console.log(newCart)
//        cart.forEach((item) => console.log(item.key))
//        console.log(Object.keys(cart).filter((product) => product != productRemove.index));
//        Object.keys(cart).forEach((item) => console.log(productRemove.index,item));
    };

    const clearCart = () => {
        setCart([]);
    };

    const addToCart = (product) => {
      setCart([...cart, product]);
    };

    useEffect(() => {
        localStorage.setItem("cart",JSON.stringify(cart));
    }, [cart]);

    return (
        <div className="Controller">
            <Appbar cart={cart}/>
            <App clearCart = {clearCart} cart={cart} addToCart={addToCart} removeFromCart={removeFromCart}/>
        </div>
    );
}

export default Controller;
