import Footer from './Footer'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pfp from './resources/profile.webp'
import Signature from './resources/signature.png'

const About= () => {
    return (
        <div className="Contact">
            <Container className = "p-4">
              <Row >
              <Col className = "p-3 text-center">
                <h2>Contemporary Panels - est 2022</h2>
                <br></br>
              <p class="text-secondary" className="text-left">In a small tin shed in suburban Melbourne over 80 years ago, Bob Sykes began creating decorative laminates. Over the years, the product range has expanded to include engineered stone, modern laminates, acrylic surfaces, timber panelling products and more. Laminex has grown up a lot since 1934, but our original intention has never changed - we believe that every Australian deserves great quality products, and great quality design.<br></br><br></br>
              As one of Australia's biggest makers of wood and paper products, we work hard to reduce our carbon footprint and respect our unique environment. We practise responsible forest management and hold timber and environmental certifications across manufacturing sites. Plus our innovative recycling and reusing programs are cutting water usage and wood waste during production.</p>
<br></br>
              <img src={Pfp}/>
              <br></br>
              <h5 class>Jono Agosta</h5>
              <h6 class>Director & Founder</h6>
              <img src={Signature}/>
              <br></br>
              </Col>
            </Row>
          </Container>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
           <Footer/>
        </div>
    )
}

export default About;