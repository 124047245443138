import { Routes, Route }    from "react-router-dom";

import Main                 from "./components/Main";
import About                from "./components/About";
import Contact              from "./components/Contact";
import Panels               from "./components/Panels";
import Cart                 from "./components/Cart.js";
import Cod                  from "./components/Cod.js";

const App = (props) => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/panels" element={<Panels cart = {props.cart} addToCart={props.addToCart}/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/cart"  element={<Cart clearCart={props.clearCart} removeFromCart = {props.removeFromCart} cart = {props.cart}/>}/>
        <Route path="/cod" element={<Cod cart = {props.cart} clearCart={props.clearCart}/>}/>
      </Routes>
    </div>
  );
}

export default App;
