import Hero from "./Hero.js";

const Main = () => {
    return (
        <div className="Main">
            <Hero/>
        </div>
    )
}

export default Main;
