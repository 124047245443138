import Button from 'react-bootstrap/Button';
import panel1 from './resources/panel1.png'
import panel2 from './resources/panel2.png'
import panel3 from './resources/panel3.png'
import card1 from './resources/card1.jpeg';
import card2 from './resources/card2.jpeg';
import card3 from './resources/card3.jpeg';
import example_1 from './resources/example_1.jpeg'
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from './Footer'

import Carousel from 'react-bootstrap/Carousel';

const Hero = () => {
    return (
        <div className="Hero">
            <Carousel>
                <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={panel1}
                  alt="First slide"
                />
                </Carousel.Item>
                <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={panel2}
                  alt="Second slide"
                />
                </Carousel.Item>
                <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={panel3}
                  alt="Third slide"
                />
                </Carousel.Item>
            </Carousel>


          
          <Container className = "p-4">
            <hr></hr>
            <br></br>
            <Row className ="text-center">
              <h1>Find Out The Latest</h1>            
            </Row>
            <Row>
              <Col className = "p-3">
              <br></br>
              <br></br>
              <h2>Fish Face by Five Foot One Design</h2>
              <br></br>
              <br></br>
              <p class="text-secondary">FishFace is a local neighbourhood restaurant, located within Sydney’s Northern Beaches. The clients wanted to bring a fresh and vibrant seafood restaurant to the area, whilst avoiding the   typical “Seafood - Coastal feel”.  </p>
              <p class="text-secondary">A new restaurant on Sydney’s Northern Beaches is inspired by its surf and seaside community while avoiding the cliches of coastal design and dining.</p>
              <br></br>
              <Button variant="secondary" size="lg">
                Explore More 
              </Button>
               </Col>
              <Col className = "p-3"> 
              <br></br>
              <br></br>
              <img className = "img-fluid" src={example_1}/></Col>
            </Row>
          </Container>

          <Container className="p-4">
          <hr></hr>
          <br></br>
            <Row className="gx-5">
              <Col><Card className="border-0">
                <img
                  className="d-block w-100"
                  src={card1}
                  alt="First slide"
                />
              <Card.Title>Product News</Card.Title>
              <Card.Subtitle>Find out more ⭢</Card.Subtitle>
              </Card></Col>
              <Col><Card className="border-0">
                <img
                  className="d-block w-100"
                  src={card2}
                  alt="First slide"
                />
              <Card.Title>Design News & Inspiration</Card.Title>
              <Card.Subtitle>Find out more ⭢</Card.Subtitle>
              </Card></Col>
              <Col><Card className="border-0">
                <img
                  className="d-block w-100"
                  src={card3}
                  alt="First slide"
                />
              <Card.Title>Business Updates</Card.Title>
              <Card.Subtitle>Find out more ⭢</Card.Subtitle>
              
              </Card></Col>
            </Row>
          </Container>
          
        <br></br>
        <br></br>

        <Footer/>
        </div>
    );
}

export default Hero;
        

