import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const fStyle = {
    "box-shadow": '0 50vh 0 50vh #f8f9fa'
};

const Footer = () => {
    return (
        <div className ="bg-light" style={fStyle}>
            <Container className="p-4">
                <Row >
                    <Col><h5>Help and Support</h5>                    
                    <ul style={{padding: 0}} type="none"> 
                    <li><a href="#" style={{ textDecoration: 'none',color:'grey' }}>Faqs</a></li>
                    <li><a href="#" style={{ textDecoration: 'none',color:'grey' }}>Care & Maintenance</a></li>
                    <li><a href="#" style={{ textDecoration: 'none',color:'grey' }}>Brochures</a></li>
                    <li><a href="#" style={{ textDecoration: 'none',color:'grey' }}>Warranties</a></li>
                    <li><a href="#" style={{ textDecoration: 'none',color:'grey' }}>National Availability Guide</a></li>
                    </ul>
                    </Col>
                    <Col><h5>About Contemporary Panels</h5>
                    <ul style={{padding: 0}} type="none"> 
                    <li><a href="#" style={{ textDecoration: 'none',color:'grey' }}>Australian Manufacturing</a></li>
                    <li><a href="#" style={{ textDecoration: 'none',color:'grey' }}>Sustainability</a></li>
                    <li><a href="#" style={{ textDecoration: 'none',color:'grey' }}>Careers</a></li>
                    <li><a href="#" style={{ textDecoration: 'none',color:'grey' }}>Newsroom</a></li>
                    <li><a href="#" style={{ textDecoration: 'none',color:'grey' }}>Privacy Policy</a></li>
                    <li><a href="#" style={{ textDecoration: 'none',color:'grey' }}>Terms and Conditions</a></li>
                    </ul>
                    </Col>
                    <Col><h5>Find a Supplier</h5>
                    <ul style={{padding: 0}} type="none"> 
                    <li><a href="#" style={{ textDecoration: 'none',color:'grey' }}>Contemporary Panels Showrooms</a></li>
                    <li><a href="#" style={{ textDecoration: 'none',color:'grey' }}>Distributors</a></li>
                    </ul>
                    </Col>
                    <Col><h5>Sample Ordering</h5>
                    <ul style={{padding: 0}} type="none"> 
                    <li><a href="#" style={{ textDecoration: 'none',color:'grey' }}>How to order samples</a></li>
                    </ul>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default Footer;
