import { useState, useEffect} from 'react';

import Footer from './Footer'
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Product1 from './resources/product1.jpeg';
import Product2 from './resources/product2.jpeg';
import Product3 from './resources/product3.jpeg';
import Product4 from './resources/product4.jpeg';
import Carousel from 'react-bootstrap/Carousel';

const Panels = (props) => {
    const [validated, setValidated] = useState(false);
    const [panelType, setPanelType] = useState('1');
    const [panelHeight, setPanelHeight] = useState('100');
    const [panelWidth, setPanelWidth] = useState('100');
    const [panelQuantity, setPanelQuantity] = useState('1');

    const calculatePrice = () => {
        const height = parseFloat(panelHeight);
        const width = parseFloat(panelWidth);
        const area = Math.sqrt(height * width)*0.09;
        return (isNaN(area) ? "Calculating...": (Math.round(area*100)/100).toFixed(2))  
    }

    const [panelPrice, setPanelPrice] = useState(calculatePrice());

    useEffect(() => {
        setPanelPrice(calculatePrice())
    }, [panelHeight,panelWidth]);


    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return
        }
        setValidated(true);
        let panelOrder = {
                "panelType" : {panelType}.panelType,
                "panelHeight" : {panelHeight}.panelHeight,
                "panelWidth" : {panelWidth}.panelWidth,
                "panelQuantity" : {panelQuantity}.panelQuantity,
                "panelPrice"    : {panelPrice}.panelPrice
        };
        props.addToCart(panelOrder);
    };


    return (
        <div className="Panels">
            <Container className="p-4">
  <Row className ="text-muted">
              <h1>Surround by Contemporary Panels</h1>            
            </Row>
                <br/>
                <Row lg={2}>
                    <Col className="d-flex">



                    <Carousel indicators="false" variant="dark">
      <Carousel.Item>
      <Card >
                        <Image src={Product1} fluid="true"/>
                        <Card.Body className="border-top">
                            <Card.Title> Panel One</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Colour Code: PR1</Card.Subtitle>
                            <Card.Text>
                            Each panel has one face routed with a regular decorative pattern which is primed with water-based paint, and a reverse flat face sealed with a white Low Pressure Melamine (LPM).  
                            </Card.Text>
                        </Card.Body>
                        <br></br>
                        <br></br>
                        </Card>
      </Carousel.Item>
      <Carousel.Item>
      <Card>
                        <Image src={Product2} fluid="true"/>
                        <Card.Body className="border-top">
                            <Card.Title> Panel Two</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Colour Code: PR2</Card.Subtitle>
                            <Card.Text>
                            Each panel has one face routed with a regular decorative pattern which is primed with water-based paint, and a reverse flat face sealed with a white Low Pressure Melamine (LPM).  
                            </Card.Text>
                        </Card.Body>
                        <br></br>
                        <br></br>
                        </Card>
      </Carousel.Item>
      <Carousel.Item>
      <Card>
                        <Image src={Product3} fluid="true"/>
                        <Card.Body className="border-top">
                            <Card.Title> Panel Three</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Colour Code: PR3</Card.Subtitle>
                            <Card.Text>
                            Each panel has one face routed with a regular decorative pattern which is primed with water-based paint, and a reverse flat face sealed with a white Low Pressure Melamine (LPM).  
                            </Card.Text>
                        </Card.Body>
                        <br></br>
                        <br></br>
                        </Card>
      </Carousel.Item>
      <Carousel.Item>
      <Card>
                        <Image src={Product4} fluid="true"/>
                        <Card.Body className="border-top">
                            <Card.Title> Panel Four</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Colour Code: PR3</Card.Subtitle>
                            <Card.Text>
                            Each panel has one face routed with a regular decorative pattern which is primed with water-based paint, and a reverse flat face sealed with a white Low Pressure Melamine (LPM).  
                            </Card.Text>
                        </Card.Body>
                        <br></br>
                        <br></br>
                        </Card>
      </Carousel.Item>
    </Carousel>
                       
                    </Col>
                    <Col className="d-flex">

                        <Card className="flex-fill">
                          <Card.Body>
                            <Card.Title>Panel</Card.Title>
                            <Card.Subtitle>Primed & Ready to Paint</Card.Subtitle>
                            <hr></hr>
                            <Card.Text className="text-muted">
                                Surround by Laminex is designed for interior applications and is manufactured from moisture resistant Medium Density Fibreboard (MDF). Each panel has one face routed with a regular decorative pattern which is primed with water-based paint. The designs of the Surround By Laminex range are protected by Australian Design Registrations.
                                </Card.Text>
                                <br></br>
                                <Card.Text>
                                    <h6>Select from our range of four unique panel types </h6>
                                </Card.Text>
                            <Card.Subtitle className="mb-2 text-muted">Custom Specifications</Card.Subtitle>

                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                               <Form.Group className="mb-3" controlId="panelType" >
                                   <FloatingLabel label="Choose Panel Type">
                                   <Form.Select value = {panelType} onChange={e => setPanelType(e.currentTarget.value)} >
                                      <option value="1">Panel One</option>
                                      <option value="2">Panel Two</option>
                                      <option value="3">Panel Three</option>
                                      <option value="4">Panel Four</option>
                                    </Form.Select> 
                                   </FloatingLabel>
                              </Form.Group>

                               <Form.Group  controlId="panelHeight" className="mb-3">
                                <FloatingLabel  label="Panel Height(MM) | Min 100 | Max 1800 ">
                                    <Form.Control value = {panelHeight} onChange = {e => setPanelHeight(e.currentTarget.value)} required type="number" min="100" max ="1800" step="any"/>
                                    <Form.Control.Feedback type="invalid">Please choose a valid height.</Form.Control.Feedback>
                                </FloatingLabel>
                              </Form.Group>
                               <Form.Group controlId="panelWidth" className="mb-3">
                                <FloatingLabel  label="Panel Width (MM) | Min 100 | Max 3600">
                                    <Form.Control value = {panelWidth} onChange = {e => setPanelWidth(e.currentTarget.value)} required type="number" min="100" max ="3600" step="any"/>
                                    <Form.Control.Feedback type="invalid">Please choose a valid width.</Form.Control.Feedback>
                                </FloatingLabel>
                              </Form.Group>
                               <Form.Group className="mb-3">
                                <FloatingLabel controlId="panelQuantity" label="Quantity">
                                    <Form.Control value = {panelQuantity} onChange = {e => setPanelQuantity(e.currentTarget.value)} required type="number" min="1"/>
                                    <Form.Control.Feedback type="invalid">Please choose a valid quantity.</Form.Control.Feedback>
                                </FloatingLabel>
                              </Form.Group>
                              <Row className="mb-3">
                                    <Col>
                                        <FloatingLabel label="Price ($)" >
                                            <Form.Control readOnly value = {panelPrice} />
                                                <Form.Control.Feedback type="invalid">Please enter valid measurements</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                    <Button size="lg"  type="submit">
                                      Purchase 
                                      </Button>
                                    </Col>
                              </Row>
                            </Form>

                          </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
           <Footer/>
        </div>
    )
}

export default Panels;
