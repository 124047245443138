import Footer from './Footer'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


const Contact = () => {
    return (
        <div className="Contact">
            <Container className = "p-4">
                <Row>

              <h2>Contact Us</h2>
                </Row>

            <Row>
              <Col className = "p-3">
              <br></br>
              <p class="text-secondary">We are always looking at ways to make Contemporary Panels a better place to shop for all your construction needs and we'd love to hear your thoughts on how we are going and any ideas you have for improving our service. <br></br><br></br>
We know that we are not always perfect and if we've done something wrong we'd love to hear from you so that we can fix it.</p>
<br></br>
              <h6 class>10 Flinders Street, Melbourne VIC 3004 </h6>
              <h6 class>0411 244 124</h6>
              <h6 class>ContemporaryPanels@contemporarypanels.com</h6>
              <br></br>
               </Col>
              <Col className = "p-3"> 
              <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Name</Form.Label>
        <Form.Control />
        <br></br>
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" />
        <br></br>
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" rows={6}/>
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
              </Col>
            </Row>
          </Container >
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
           <Footer />
        </div>
    )
}

export default Contact;
