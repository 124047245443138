import { useState } from 'react'
import Footer from './Footer'
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';


function Cod(props) {

    const  [validated, setValidated] = useState(false);
    const  [fName,setFName] = useState("");
    const  [lName,setLName] = useState("");
    const  [email,setEmail] = useState("");
    const  [address,setAddress] = useState("");
    const  [address2,setAddress2] = useState("");
    const  [city,setCity] = useState("");
    const  [postCode,setPostCode] = useState("");
    const  [state, setState] = useState('vic');

    const handleSubmit = async(event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return
        }
        setValidated(true)
        const codDetails = {
            "firstName":fName,
            "lastName":lName,
            "email":email,
            "address":address,
            "address2":address2,
            "city":city,
            "postCode":postCode,
            "state":state
        }
      const payload = {
        cart:props.cart,
        customer:codDetails
      }

      await fetch("http://localhost:3002/record/add", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      })
      .catch(error => {
        window.alert(error);
        return;
      });
        console.log(props.cart,codDetails);
    }
    const i = 0;
    const cartTotal = props.cart.reduce((a,c) => a + parseFloat(c.panelPrice)*parseFloat(c.panelQuantity), i)
    const finalTotal = (Math.round(cartTotal*100)/100).toFixed(2)


  return (
    <div className="cod">
    <Container className="p-4">
      <Row className="mb-3">
      <h1>Cash on Delivery </h1>
      </Row>
      <Row className="mb-3">
      <h3 class="text-secondary"> Review Order </h3>
            <Table hover>
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                {props.cart.map((item, index) => (
                    <tr key={index}>
                        <td><b>PanelType: </b>&emsp;{item.panelType}<br></br>
                        <b>Height:</b>&emsp;&emsp;&emsp;{item.panelHeight}mm<br></br>
                        <b>Width: </b>&emsp;&emsp;&emsp;{item.panelWidth}mm</td>
                        <td>{item.panelQuantity}</td>
                        <td>${item.panelPrice}</td>
                        <td>${(Math.round(parseFloat(item.panelPrice) * parseFloat(item.panelQuantity)*100)/100).toFixed(2)}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <Table hover>
                <thead>
                    <tr>
                        <th>Total</th>
                    </tr>
                </thead>
            </Table>
            <h6>${finalTotal} AUD</h6>
            <br></br>
      </Row>
      <h3>Customer Information </h3>
      <br></br>
    <Form onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formFName">
          <Form.Label>First Name</Form.Label>
          <Form.Control value = {fName} type="text" onChange = {e => setFName(e.currentTarget.value)}
            required />
        </Form.Group>

        <Form.Group as={Col} controlId="formLName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control value = {lName} type="text" onChange = {e => setLName(e.currentTarget.value)}
            required />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control value = {email} type="email" onChange = {e => setEmail(e.currentTarget.value)}
            required />
        </Form.Group>
      </Row>


      <Form.Group className="mb-3" controlId="formAddress1">
        <Form.Label>Address</Form.Label>
          <Form.Control value = {address} type="text" onChange = {e => setAddress(e.currentTarget.value)}
            required />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formAddress2">
        <Form.Label>Address 2 (Apartment, studio, or floor) </Form.Label>
          <Form.Control value = {address2} type="text" onChange = {e => setAddress2(e.currentTarget.value)}
            required />
      </Form.Group>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formCity">
          <Form.Label>City</Form.Label>
          <Form.Control value = {city} type="text" onChange = {e => setCity(e.currentTarget.value)}
            required />
        </Form.Group>

        <Form.Group as={Col} controlId="formState">
          <Form.Label>State</Form.Label>
          <Form.Select value = {state} onChange={e => setState(e.currentTarget.value)}>
            <option value="nsw">NSW</option>
            <option value="vic">VIC</option>
            <option value="qld">QLD</option>
            <option value="wa">WA</option>
            <option value="sa">SA</option>
            <option value="tas">TAS</option>
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="formPostCode">
          <Form.Label>PostCode</Form.Label>
          <Form.Control value = {postCode} onChange = {e => setPostCode(e.currentTarget.value)} required type="number"/>
        </Form.Group>
      </Row>
      <Button variant="primary" type="submit">
        Checkout
      </Button>
    </Form>
    <br></br>
    <br></br>
    </Container>
      <Footer/>
    </div>
  );
}

export default Cod;