import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OnlinePay from './OnlinePay.js';
import Footer from './Footer';

const Cart = (props) => {
    const navigate = useNavigate();
    const navigateCart = () => {
        navigate("/cod");
    };
    const i = 0;
    const cartTotal = props.cart.reduce((a,c) => a + parseFloat(c.panelPrice)*parseFloat(c.panelQuantity), i)
    const finalTotal = (Math.round(cartTotal*100)/100).toFixed(2)
    return (
        <div className="Cart">
            <Container className= "p-4">
            <Row>
            <Col>
            <h2>Shopping Cart</h2>
            <hr></hr>
            <Table hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                {props.cart.map((item, index) => (
                    <tr key={index}>
                        <td><Button onClick={() => props.removeFromCart({index})}  variant="light">x</Button></td>
                        <td><b>PanelType: </b>&emsp;{item.panelType}<br></br>
                        <b>Height:</b>&emsp;&emsp;&emsp;{item.panelHeight}mm<br></br>
                        <b>Width: </b>&emsp;&emsp;&emsp;{item.panelWidth}mm</td>
                        <td>{item.panelQuantity}</td>
                        <td>${item.panelPrice}</td>
                        <td>${(Math.round(parseFloat(item.panelPrice) * parseFloat(item.panelQuantity)*100)/100).toFixed(2)}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
            </Col>

            <Col>
            <h2>Cart Totals</h2>
            <hr></hr>
            <Table hover>
                <thead>
                    <tr>
                        <th>Total</th>
                    </tr>
                </thead>
            </Table>
            <h6>${finalTotal} AUD</h6>
            <br></br>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Cash on Delivery (Pickup)</Accordion.Header>
                        <Accordion.Body>
                        <Button onClick={navigateCart}>Order</Button>
                        </Accordion.Body>
                    </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Online Payment (Pickup)</Accordion.Header>
                        <Accordion.Body>
                            <OnlinePay cart = {props.cart} clearCart={props.clearCart}/>
                        </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            </Col>
            </Row>
            </Container>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
            <Footer/>
        </div>
    )
}

export default Cart;
