import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


const ProductDisplay = (props) => (
  <section>
    <Form onSubmit={props.clearCart} action='/create-checkout-session' method="POST" >
      <Button type="submit">
       Pay Online 
      </Button>
    </Form>
  </section>
);

const OnlinePay = (props) => {

    return(
    <ProductDisplay/>
  );

}

export default OnlinePay;
